/*
 * @Description: 新增和详情的表单校验和枚举获取逻辑
 * @Author: LiangYiNing
 * @Date: 2021-12-22 11:15:10
 * @LastEditTime: 2022-07-19 15:30:19
 * @LastEditors: ChenXueLin
 */
import {
  findDownList,
  getCompanySpecialDownList,
  getCorpAttrDownList,
  invoiceDataDownListFormTianyancha
} from "@/api";
import { printError } from "@/utils/util";

export default {
  data() {
    return {
      billingCorpNameLoading: false,
      corpReferralLoading: false,
      parentCorpLoading: false,
      bssTypeEnum: [], // 企业类型
      invoicePeopleTypeEnum: [], // 纳税人类别
      invoiceTypeEnum: [], // 开票类型
      corpProductLineTypeEnum: [], // 产品线枚举
      corpDiffEnum: [], // 客户类型枚举
      corpCategoryEnum: [], // 客户类别枚举
      corpStatusEnum: [], // 客户状态枚举
      corpBizTypeEnum: [], // 客户业务类型枚举
      corpSourceEnum: [], // 客户来源枚举
      companyEnum: [], //主体信息枚举
      corpReferralEnum: [], //客户转介绍 客户列表枚举
      parentCorpEnum: [], //客户上级公司 客户列表枚举
      corpAttrEnum: [], //客户属性枚举
      corpLevelEnum: [], //客户级别枚举
      corpClassEnum: [], //客户分级枚举
      corpFeeTypeEnum: [], //费用类型枚举
      allUserEnum: [], // 员工枚举
      allUserIncludesQuitEnum: [], // 员工枚举(包括已离职)
      billingCorpNameEnum: [], // 天眼查开票信息枚举
      corpImpactEnum: [], // 客户影响力枚举，
      corpInstallreasonEnum: [], //安装初衷枚举
      enumKey: [
        "bssType",
        "invoicePeopleType",
        "invoiceType",
        "corpDiff",
        "corpCategory",
        "corpStatus",
        "corpBizType",
        "corpSource",
        "corpLevel",
        "corpClass",
        "corpFeeType",
        "corpImpact",
        "corpInstallreason",
        "corpProductLineType"
      ],
      propsEnum: {
        id: "codeValue",
        label: "codeName"
      }
    };
  },
  computed: {
    //开票信息校验
    invoiceDataRules() {
      return {
        billingCorpId: [
          {
            required: true,
            message: "请输入开票名称",
            trigger: ["blur", "change"]
          }
        ]
      };
    },
    //基本信息校验
    corpBaseRules() {
      let illegalCharacter = /[[ _`!@#$%^&*+=|{}’:;’,.<>/\\?！@#¥%……&*——+|{}【】‘；：”“’。，、？\]|\n|\r|\t]+$/;
      let validateIllegalCharacter = (rule, value, callback) => {
        // 企业类型为个人时增加的校验规则
        if (
          value &&
          illegalCharacter.test(value) &&
          this.corpInvoiceInfo.bssType == "1"
        ) {
          return callback(new Error("输入内容含有非法特殊字符"));
        }
        callback();
      };
      return {
        corpName: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: ["blur", "change"]
          },
          { validator: validateIllegalCharacter, trigger: ["blur", "change"] }
        ],
        systemCorpName: [
          { validator: validateIllegalCharacter, trigger: ["blur", "change"] }
        ],
        corpSimName: [
          { validator: validateIllegalCharacter, trigger: ["blur", "change"] }
        ],
        salesman: [
          {
            required: true,
            message: "请选择业务员",
            trigger: ["blur", "change"]
          }
        ],
        companyId: [
          {
            required: true,
            message: "请选择所属主体",
            trigger: ["blur", "change"]
          }
        ],
        // corpCategory: [
        //   {
        //     required: true,
        //     message: "请选择客户类别",
        //     trigger: ["blur", "change"]
        //   }
        // ],
        corpSource: [
          {
            required: true,
            message: "请选择客户来源",
            trigger: ["blur", "change"]
          }
        ],
        corpDiff: [
          {
            required: true,
            message: "请选择客户类型",
            trigger: ["blur", "change"]
          }
        ],
        corpBizType: [
          {
            required: true,
            message: "请选择业务类型",
            trigger: ["blur", "change"]
          }
        ],
        corpStatus: [
          {
            required: true,
            message: "请选择客户状态",
            trigger: ["blur", "change"]
          }
        ],
        corpLevel: [
          {
            required: true,
            message: "请选择客户级别",
            trigger: ["blur", "change"]
          }
        ],
        corpClass: [
          {
            required: true,
            message: "请选择客户分级",
            trigger: ["blur", "change"]
          }
        ],
        isMaster: [
          {
            required: true,
            message: "请选择是否总公司",
            trigger: ["blur", "change"]
          }
        ],
        autoConfirm: [
          {
            required: true,
            message: "请选择是否自动确认",
            trigger: ["blur", "change"]
          }
        ]
      };
    }
  },
  created() {
    this.initData();
  },
  methods: {
    // 开票过滤 远程搜索
    handleFilter: _.debounce(async function(val) {
      if (val) {
        this.invoiceDataDownListFormTianyancha(val);
        this.detailInfo.billingCorpName = "";
      }
    }, 300),
    async invoiceDataDownListFormTianyancha(val) {
      try {
        this.billingCorpNameLoading = true;
        let res = await invoiceDataDownListFormTianyancha({
          billingCorpName: val
        });
        if (res.code === "OK") {
          this.billingCorpNameEnum = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          this.billingCorpNameEnum = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.billingCorpNameLoading = false;
      }
    },

    // 清空表单校验
    clearValidateInvoceForm() {
      this.$nextTick(() => {
        this.$refs.corpInvoiceInfo.clearValidate();
      });
    },
    // 纳税人类别改变
    invoicePeopleTypeChange() {
      this.corpInvoiceInfo.invoiceType = "1";
      this.clearValidateInvoceForm();
    },
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = this.enumKey;
        //获取枚举数据
        let promiseList = [
          findDownList(enumKey),
          getCompanySpecialDownList({ isManageEmp: false }),
          getCorpAttrDownList({})
        ];
        let [findDownListRes, companyEnumRes, corpAttrRes] = await Promise.all(
          promiseList
        );
        enumKey.map(item => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`
          });
        });
        this.companyEnum = this.getFreezeResponse(companyEnumRes, {
          path: "data"
        });
        this.corpAttrEnum = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    }
  }
};
